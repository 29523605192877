<template>
  <div class="welcome">
    <h2 class="welcome__heading">Bienvenu ! <strong></strong></h2>
    <p class="welcome__paragraph">
      Afin d'améliorer nos communautés et de garantir une excellente expérience à nos utilisateurs
      vous invitaient à faire un test rapide de 5 min maximum, pour vous donner accès au forum.
    </p>
    <router-link :to="{name:'Home'}" class="welcome__next">
      Suivant
    </router-link>
  </div>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
  .welcome{
    @apply bg-white rounded-md
    px-7 py-10 md:px-20 lg:px-[118px] pt-[118px] pb-[58px];
    &__heading{
      @apply text-lg leading-6
      mb-8;
    }
    &__paragraph{
      @apply text-lg leading-9
      mb-13;
    }
    &__next{
      @apply inline-block text-center 
      py-2 w-full md:max-w-[329px] 
    text-white bg-primary
      rounded-md;
    }
  }
</style>